import React from "react";
import { useState } from "react";
import FormModal from "./FormModal";

const Form = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [info, setInfo] = useState({ infoText: "", success: false });
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log(process.env.GATSBY_API_URL);
  //ajax form submission
  function formSubmit(e) {    
    e.preventDefault();
    setDisabled(true);
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "Marke",
      document.querySelector('select[name="Marke"]').value
    );
    formData.append(
      "Modelis",
      document.querySelector('input[name="Modelis"]').value
    );
    formData.append(
      "Pagaminimo-metai",
      document.querySelector('input[name="Metai"]').value
    );
    formData.append(
      "Kuro-tipas",
      document.querySelector('select[name="Kuras"]').value
    );
    formData.append(
      "Pageidaujama-kaina",
      document.querySelector('input[name="Kaina"]').value
    );
    formData.append(
      "Miestas",
      document.querySelector('input[name="Miestas"]').value
    );
    formData.append(
      "El-pastas",
      document.querySelector('input[name="pastas"]').value
    );
    formData.append(
      "Telefono-numeris",
      document.querySelector('input[name="Telefonas"]').value
    );
    formData.append(
      "Papildomas-komentaras",
      document.querySelector('textarea[name="Komentaras"]').value
    );
          fetch(`${process.env.GATSBY_API_URL}`, {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              
              e.target.reset();
              setLoading(false);
              if (response.redirected === false) {
                setInfo({
                  infoText: "Įvyko nenumatyta klaida. Bandykite vėliau",
                  success: false,
                });
              } else {
                setInfo({
                  infoText: "Forma išsiųsta sėkmingai",
                  success: true,
                });
              }
              setIsModalOpen(true);
            })
            .catch((error) => {
              setLoading(false);
              e.target.reset();
              setInfo({
                infoText: "Įvyko nenumatyta klaida. Bandykite vėliau",
                success: false,
              });
              setIsModalOpen(true);
            });
  }

  return (
    <>
      <form
        onSubmit={(e) => formSubmit(e)}
        id="autoForm"
        action=""
        className="w-full mx-auto p-6"
      >
        <div className="flex flex-wrap -mx-3">
          <div className="w-full px-3 mb-6 md:mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 whitespace-nowrap"
              htmlFor="grid-marke"
            >
              Automobilio markė *
            </label>
            <div className="relative">
              <select
                className="cursor-pointer block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white focus:border-gray-500"
                id="grid-marke"
                name="Marke"
              >
                <option value="">---</option>
                <option value="Acura">Acura</option>
                <option value="Aixam">Aixam</option>
                <option value="Alfa-Romeo">Alfa-Romeo</option>
                <option value="Asia">Asia</option>
                <option value="Aston Martin">Aston Martin</option>
                <option value="Audi">Audi</option>
                <option value="Austin">Austin</option>
                <option value="Autobianchi">Autobianchi</option>
                <option value="Bentley">Bentley</option>
                <option value="BMW">BMW</option>
                <option value="Brilliance">Brilliance</option>
                <option value="Bugatti">Bugatti</option>
                <option value="Buick">Buick</option>
                <option value="Cadillac">Cadillac</option>
                <option value="Chatenet">Chatenet</option>
                <option value="Citroen">Citroen</option>
                <option value="Comarth">Comarth</option>
                <option value="Chrysler">Chrysler</option>
                <option value="Dacia">Dacia</option>
                <option value="Daewoo">Daewoo</option>
                <option value="Daihatsu">Daihatsu</option>
                <option value="De Lorean">De Lorean</option>
                <option value="DFSK">DFSK</option>
                <option value="DKW">DKW</option>
                <option value="Dodge">Dodge</option>
                <option value="Eagle">Eagle</option>
                <option value="FAW">FAW</option>
                <option value="Ferrari">Ferrari</option>
                <option value="Fiat">Fiat</option>
                <option value="Ford">Ford</option>
                <option value="Galloper">Galloper</option>
                <option value="GAZ">GAZ</option>
                <option value="Geely">Geely</option>
                <option value="GMC">GMC</option>
                <option value="Gonow">Gonow</option>
                <option value="Grecav">Grecav</option>
                <option value="GWM">GWM</option>
                <option value="Hyundai">Hyundai</option>
                <option value="Holden">Holden</option>
                <option value="Honda">Honda</option>
                <option value="Hummer">Hummer</option>
                <option value="Infiniti">Infiniti</option>
                <option value="Isuzu">Isuzu</option>
                <option value="Yugo">Yugo</option>
                <option value="Iveco">Iveco</option>
                <option value="Jaguar">Jaguar</option>
                <option value="Jeep">Jeep</option>
                <option value="Kaipan">Kaipan</option>
                <option value="Kia">Kia</option>
                <option value="Lada">Lada</option>
                <option value="Lamborghini">Lamborghini</option>
                <option value="Lancia">Lancia</option>
                <option value="Land-Rover">Land-Rover</option>
                <option value="Lexus">Lexus</option>
                <option value="Ligier">Ligier</option>
                <option value="Lincoln">Lincoln</option>
                <option value="Lotus">Lotus</option>
                <option value="LTI">LTI</option>
                <option value="LuAZ">LuAZ</option>
                <option value="Mahindra">Mahindra</option>
                <option value="Maybach">Maybach</option>
                <option value="Maruti">Maruti</option>
                <option value="Maserati">Maserati</option>
                <option value="Mazda">Mazda</option>
                <option value="McLaren">McLaren</option>
                <option value="Mercedes-Benz">Mercedes-Benz</option>
                <option value="Mercury">Mercury</option>
                <option value="MG">MG</option>
                <option value="Microcar">Microcar</option>
                <option value="MINI">MINI</option>
                <option value="Mitsubishi">Mitsubishi</option>
                <option value="Morgan">Morgan</option>
                <option value="Moskvich">Moskvich</option>
                <option value="Nysa">Nysa</option>
                <option value="Nissan">Nissan</option>
                <option value="NSU">NSU</option>
                <option value="Oldsmobile">Oldsmobile</option>
                <option value="Oltcit">Oltcit</option>
                <option value="Opel">Opel</option>
                <option value="Peugeot">Peugeot</option>
                <option value="Piaggio">Piaggio</option>
                <option value="Plymouth">Plymouth</option>
                <option value="Polonez">Polonez</option>
                <option value="Pontiac">Pontiac</option>
                <option value="Porsche">Porsche</option>
                <option value="Proton">Proton</option>
                <option value="Rayton Fissore">Rayton Fissore</option>
                <option value="Renault">Renault</option>
                <option value="Rolls-Royce">Rolls-Royce</option>
                <option value="Rover">Rover</option>
                <option value="Saab">Saab</option>
                <option value="Samsung">Samsung</option>
                <option value="Santana">Santana</option>
                <option value="Santana">Santana</option>
                <option value="Saturn">Saturn</option>
                <option value="Scion">Scion</option>
                <option value="Seat">Seat</option>
                <option value="Shuanghuan">Shuanghuan</option>
                <option value="Syrena">Syrena</option>
                <option value="Skoda">Skoda</option>
                <option value="Smart">Smart</option>
                <option value="Ssangyong">Ssangyong</option>
                <option value="Subaru">Subaru</option>
                <option value="Suzuki">Suzuki</option>
                <option value="Talbot">Talbot</option>
                <option value="Tarpan">Tarpan</option>
                <option value="Tata">Tata</option>
                <option value="Tatra">Tatra</option>
                <option value="Tavria">Tavria</option>
                <option value="Tesla">Tesla</option>
                <option value="Toyota">Toyota</option>
                <option value="Trabant">Trabant</option>
                <option value="Triumph">Triumph</option>
                <option value="TVR">TVR</option>
                <option value="UAZ">UAZ</option>
                <option value="Vauxhall">Vauxhall</option>
                <option value="Volga">Volga</option>
                <option value="Volkswagen">Volkswagen</option>
                <option value="Volvo">Volvo</option>
                <option value="Warszawa">Warszawa</option>
                <option value="Wartburg">Wartburg</option>
                <option value="Weismann">Weismann</option>
                <option value="Zastava">Zastava</option>
                <option value="ZAZ">ZAZ</option>
                <option value="Zuk">Zuk</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 whitespace-nowrap"
              htmlFor="grid-modelis"
            >
              Automobilio modelis *
            </label>
            <input
              className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
              id="grid-modelis"
              name="Modelis"
              type="text"
              placeholder="Modelis"
              required
              maxLength="250"
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 whitespace-nowrap"
              htmlFor="grid-year"
            >
              Pagaminimo metai *
            </label>
            <input
              className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
              id="grid-year"
              name="Metai"
              type="number"
              min={"1900"}
              placeholder="Pagaminimo metai"
              required
              maxLength="250"
            />
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 whitespace-nowrap"
              htmlFor="grid-fuel"
            >
              Kuro tipas
            </label>
            <div className="relative">
              <select
                className=" cursor-pointer block appearance-none w-full border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none bg-white focus:border-gray-500"
                id="grid-fuel"
                name="Kuras"
              >
                <option value="Benzinas">Benzinas</option>
                <option value="Dyzelis">Dyzelis</option>
                <option value="Benzinas/Dujos">Benzinas/Dujos</option>
                <option value="Benzinas/Elektra">Benzinas/Elektra</option>
                <option value="Elektra">Elektra</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 whitespace-nowrap"
              htmlFor="grid-price"
            >
              Pageidaujama kaina (eur) *
            </label>
            <input
              className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
              id="grid-price"
              name="Kaina"
              type="number"
              placeholder="Pageidaujama kaina"
              required
              maxLength="250"
            />
          </div>
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 whitespace-nowrap"
              htmlFor="grid-city"
            >
              Miestas *
            </label>
            <input
              className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
              id="grid-city"
              name="Miestas"
              type="text"
              placeholder="Miestas"
              required
              maxLength="250"
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 whitespace-nowrap"
              htmlFor="grid-pastas"
            >
              El. paštas *
            </label>
            <input
              className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
              id="grid-pastas"
              name="pastas"
              type="email"
              placeholder="El. pašto adresas"
              required
              maxLength="250"
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 whitespace-nowrap"
              htmlFor="grid-phone"
            >
              Telefono numeris *
            </label>
            <input
              className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500"
              id="grid-phone"
              name="Telefonas"
              type="tel"
              placeholder="Telefono numeris"
              required
              maxLength="250"
            />
          </div>
          <div className="w-full md:w-full px-3 mb-6 md:mb-4">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 whitespace-nowrap"
              htmlFor="grid-area"
            >
              Papildomas komentaras
            </label>
            <textarea
              className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none bg-white focus:border-gray-500 resize-none"
              id="grid-area"
              name="Komentaras"
              type="textarea"
              rows="4"
              maxLength="1000"
              placeholder="Durų skaičius, kėbulo tipas, variklio tūris..."
            />
          </div>
        </div>
        <button
          className="transition-all delay-150 bg-black hover:bg-white border-2 border-black text-white hover:text-black text-uppercase w-full font-bold p-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
          disabled={disabled}
        >
          {loading ? (
            <span>
              <svg
                className="animate-spin inline-block -ml-1 mr-3 h-5 w-5 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Siunčiama...
            </span>
          ) : (
            "Pateikti užklausą"
          )}
        </button>
      </form>
      {isModalOpen ? (
        <FormModal
          info={info}
          setIsModalOpen={setIsModalOpen}
          setDisabled={setDisabled}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Form;
