import * as React from "react";
import NavComponent from "../components/NavComponent";
import FooterComponent from "../components/FooterComponent";
import SEO from "../components/SEO";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import Form from "./elements/Form";
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bannerFluidImage: file(relativePath: { eq: "parduok_banner.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      purchaseFluidImage: file(relativePath: { eq: "car-purchase.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      testimonialSecond: file(relativePath: { eq: "testimonial-2.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      testimonialThird: file(relativePath: { eq: "testimonial-3.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      testimonialFirst: file(relativePath: { eq: "testimonial-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      carColumnFluid: file(relativePath: { eq: "red-car.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      processOne: file(relativePath: { eq: "process-1.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      processTwo: file(relativePath: { eq: "process-2.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `);
  const image = getImage(data.bannerFluidImage);
  const purchaseImage = getImage(data.purchaseFluidImage);

  return (
    <main className="font-body bg-gray-100">
      <NavComponent />
      <div className="w-full">
        <GatsbyImage
          image={image}
          alt="Parduok baneris"
          className="min-h-[300px] w-full object-cover"
        />
      </div>

      <h3
        id="formHeader"
        className="text-center px-6 text-2xl md:text-4xl my-6 font-bold"
      >
        Gaukite pasiūlymą
      </h3>
      <div className="container mx-auto grid grid-cols-1 gap-8 lg:grid-cols-2 bg-white shadow">
        <Form />
        <div>
          <GatsbyImage
            image={purchaseImage}
            alt="Automobilis pardavimui"
            className="min-h-screen lg:h-full object-cover"
          />
        </div>
      </div>

      <h2
        id="aboutHeader"
        className="text-2xl md:text-4xl px-6 font-bold text-center text-uppercase my-8"
      >
        Superkame automobilius visoje lietuvoje -{" "}
        <span className="text-red-500">taupome jūsų laiką</span>
      </h2>
      <div className="container p-6 mx-auto my-5 shadow-xl bg-white">
        <p className="pb-8">
          Nusibodo gaišti laiką skelbimams talpinti, tariamų pirkėjų noro „šiaip
          apsižvalgyti“? nusibodo išvien tie patys klausimai telefonu, kuriame
          mieste yra automobilis – Vilniuje? Kaune? Auto Lietuvoje? Kuris laikas
          nesiseka sėkmingai parduoti savo automobilio(-ių)? Automobilis jau
          ilgą laiką tik užima vietą kieme ir yra nevažinėjamas? Jeigu bent į
          vieną klausimą atsakėte taip, Jūs esate tinkamiausioje vietoje!
        </p>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
          <div className="text-center">
            <GatsbyImage
              image={getImage(data.processOne)}
              alt="Procesas 1"
              className="object-cover text-center mb-4 rounded-full border-2"
            />
            <h3 className="font-bold text-uppercase text-2xl md:text-3xl pb-6 text-center">
              Automobilių supirkimas Vilniuje
            </h3>
            <p className="text-center">
              Patikimas ir profesionalus automobilių supirkėjas jūsų nevers
              nekaip jaustis, netampys po servisus, ir negaišins jūsų laiko, jis
              paprasčiausiai apžiūrės ir įvertins jūsų automobilio būklę ir
              pasiūlys už automobilį savo kainą. Užtenka paskambinti supirkėjui
              ir jis pats pas jus atvažiuos nedelsiant, be to atsiskaitoma
              iškarto pasirašius sutartį ir beliks tik išregistruoti automobilį.
              Palankiomis sąlygomis automobilio pardavimas gali trukti vos
              pusvalandį.
            </p>
          </div>
          <div className="text-center">
            <GatsbyImage
              image={getImage(data.processTwo)}
              alt="Procesas 2"
              className="object-cover text-center mb-4 rounded-full border-2"
            />
            <h3 className="font-bold text-2xl md:text-3xl pb-6 text-center">
              Superkame ir naudotus automobilius
            </h3>
            <p className="text-center">
              Jeigu Jūsų automobilis yra senas ir nevažiuojantis – mes jį
              supirksime. Jeigu turite daužtą eismo įvykyje transporto priemonę,
              mes pasirūpinsime daužtų automobilių supirkimu už Jus. Apynaujų,
              techniškai tvarkingų ir netvarkingų automobilių supirkimas – pas
              mus viskas įmanoma. Automobilių supirkimas Vilniuje už{" "}
              <b>geriausią kainą</b> per <b>trumpą laiką</b>.
            </p>
          </div>
          <div className="text-center">
            <GatsbyImage
              image={getImage(data.processOne)}
              alt="Procesas 1"
              className="object-cover text-center mb-4 rounded-full border-2"
            />
            <h3 className="font-bold text-2xl md:text-3xl pb-6 text-center">
              Senų automobilių supirkimas
            </h3>
            <p className="text-center">
              Senų transporto priemonių{" "}
              <b>supirkimo sąlygos yra tokios pačios</b>, kaip naujų automobilių
              supirkimas. Pirmiausiai, tai brangus supirkimas. Visada siūlome{" "}
              <b>palankiausią kainą</b> ir savo paslaugas teikiame visą parą,
              darbo dienomis ir savaitgaliais.
            </p>
          </div>
        </div>
      </div>

      <h2 className="text-2xl md:text-4xl px-6 font-bold text-center my-8">
        Supirktų automobilių klientai sako apie mus
      </h2>

      <div className="container px-6 md:px-0 mx-auto my-8 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
        <div className="py-4 px-6 bg-white shadow-lg rounded-lg my-10">
          <div className="flex justify-center md:justify-end -mt-16">
            <GatsbyImage
              image={getImage(data.testimonialFirst)}
              alt="First testimonial"
              className="w-20 h-20 object-cover rounded-full border-2"
            />
          </div>
          <div>
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>First star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Second star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Third star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fourth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fifth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
            </div>
            <h2 className="text-gray-800 text-2xl md:text-3xl font-semibold">
              Pinigus gavau per 20 minučių
            </h2>
            <p className="mt-2 text-gray-600">
              Puikiai atliktas darbas, automobilis nupirktas ir išsivežtas per
              20 minučių! Super, ačiū.
            </p>
          </div>
          <div className="flex justify-end mt-4">
            <p className="text-xl font-bold text-black">Paulius</p>
          </div>
        </div>

        <div className="py-4 px-6 bg-white shadow-lg rounded-lg my-10">
          <div className="flex justify-center md:justify-end -mt-16">
            <GatsbyImage
              image={getImage(data.testimonialSecond)}
              alt="Second testimonial"
              className="w-20 h-20 object-cover rounded-full border-2"
            />
          </div>
          <div>
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>First star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Second star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Third star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fourth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fifth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
            </div>
            <h2 className="text-gray-800 text-2xl md:text-3xl font-semibold">
              Nesitikėjau tiek gauti
            </h2>
            <p className="mt-2 text-gray-600">
              Nors mano mašina buvo jau gana parūdijusi, už ją gavau didesnę
              sumą, nei tikėjausi iš supirktuvės.
            </p>
          </div>
          <div className="flex justify-end mt-4">
            <p className="text-xl font-bold text-black">Raminta</p>
          </div>
        </div>

        <div className="py-4 px-6 bg-white shadow-lg rounded-lg my-10">
          <div className="flex justify-center md:justify-end -mt-16">
            <GatsbyImage
              image={getImage(data.testimonialThird)}
              alt="Third testimonial"
              className="w-20 h-20 object-cover rounded-full border-2"
            />
          </div>
          <div>
            <div className="flex items-center">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>First star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Second star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Third star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fourth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-yellow-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Fifth star</title>
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
              </svg>
            </div>
            <h2 className="text-gray-800 text-2xl md:text-3xl font-semibold">
              Be problemų
            </h2>
            <p className="mt-2 text-gray-600">
              Automobilis turėjo nemažai defektų, todėl būtų buvę labai
              problematiška rasti jam pirkėją. Auto supirktuvei neturėjau
              aiškintis, tiesiog gavau už automobilį tiek, kiek jis buvo vertas
              ir sutaupiau daug nervų.
            </p>
          </div>
          <div className="flex justify-end mt-4">
            <p className="text-xl font-bold text-black">Valdas</p>
          </div>
        </div>
      </div>
      <div className="container mx-auto grid grid-cols-1 my-8 lg:grid-cols-2">
        <div className="bg-black position-relative text-center pt-6 pb-8 px-6 flex justify-center flex-col">
          <p
            id="contactHeader"
            className="text-2xl md:text-4xl text-white uppercase pb-6"
          >
            Susisiekite!
          </p>
          <div className="bg-white rounded text-black p-4">
            <p className="text-xl xl:text-2xl lg:text-lg pb-2 break-all">
              <span className="block sm:inline-block mr-2">Skambinkite: </span>
              <a
                href="tel:+37064119119"
                target="_blank"
                rel="noreferrer"
                className="text-yellow-700 text-base xl:text-2xl lg:text-lg font-semibold hover:text-yellow-500"
              >
                +370 64 119 119
              </a>
            </p>
            <p className="text-xl xl:text-2xl lg:text-lg pb-2">
              <span className="block sm:inline-block mr-2">Rašykite:</span>
              <a
                href="mailto:superkameautomobilius@gmail.com"
                target="_blank"
                rel="noreferrer"
                className="text-yellow-700 text-base xl:text-2xl lg:text-lg font-semibold hover:text-yellow-500 break-all"
              >
                superkameautomobilius@gmail.com
              </a>
            </p>
            <p className="text-xl xl:text-2xl lg:text-lg">
              <span className="block sm:inline-block mr-2">
                Automobilių pirkimo - pardavimo sutartis:
              </span>

              <a
                href="#document-download"
                download
                target="_blank"
                rel="noreferrer"
                className="text-yellow-700 text-base xl:text-2xl lg:text-lg font-semibold hover:text-yellow-500"
              >
                atsisiųsti
              </a>
            </p>
          </div>
        </div>
        <div className="bg-white">
          <GatsbyImage
            image={getImage(data.carColumnFluid)}
            alt="Sutarties pasirašymas"
            className="object-cover max-h-[300px] lg:max-h-[100%] lg:h-100 w-full"
          />
        </div>
      </div>
      <FooterComponent />
    </main>
  );
};

export const Head = () => <SEO />;

export default IndexPage;
